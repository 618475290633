import React from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { Button, Header } from 'semantic-ui-react';
import RenderFieldRedux from '../common/RenderFieldRedux';
import axios from 'axios';




class CompanyInfo extends React.Component {
  onPaste = (e) => {
    e.preventDefault();
  }
  
  render() {
    const { handleSubmit, previousPage } = this.props;

    return (
      <form onSubmit={handleSubmit} size='large'>
        <Header>
          Please fill in all the fields below and click Next to continue
        </Header>
        <Field
          name="username"
          component={RenderFieldRedux}
          type="text"
          label="Username"
        />
        <Field
          name="name"
          component={RenderFieldRedux}
          type="text"
          label="Name"
        />
        <Field
          name="phone"
          component={RenderFieldRedux}
          type="text"
          label="Phone"
        />
        <Field
          name="magazine"
          component={RenderFieldRedux}
          type="text"
          label="Magazine Title"
        />
        <Field
          name="email"
          component={RenderFieldRedux}
          type="text"
          label="Email Address"
          autocomplete="new-email"
        />
        <Field
          name="confirmemail"
          component={RenderFieldRedux}
          type="text"
          label="Confirm Email Address"
          autocomplete="new-email"
          onpaste={this.onPaste}
        />
        <Field
          name="password"
          component={RenderFieldRedux}
          type="password"
          label="Password"
          autocomplete="new-password"
        />
        <Field
          name="password2"
          component={RenderFieldRedux}
          type="password"
          label="Confirm Password"
          onpaste={this.onPaste}
        />
        <Button color='blue' style={{ background: '#ea5348'}}
          onClick={previousPage} >
          Previous
        </Button>
        <Button color='blue'  style={{ background: '#ea5348'}}>
          Next
        </Button>
      </form>
    )
  }
}

const validate = (formValues) => {
  const errors = {};

  if (!formValues.username) {
    errors.username = 'Required'
  }

  if (!formValues.name) {
    errors.name = 'Required'
  }

  if (!formValues.phone) {
    errors.phone = 'Required'
  }

  if (!formValues.magazine) {
    errors.magazine = 'Required'
  }

  if (!formValues.email) {
    errors.email = 'Required'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email)) {
    errors.email = 'Invalid email address'
  }

  if (!formValues.confirmemail) {
    errors.confirmemail = 'Required'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.confirmemail)) {
    errors.confirmemail = 'Invalid email address'
  } else if (formValues.email !== formValues.confirmemail) {
    errors.confirmemail = 'Emails must match. Please re-enter'
  }

  if (!formValues.password) {
    errors.password = 'Required'
  } else if (formValues.password.length < 6) {
    errors.password = 'Must be greater than 6 characters'
  }

  if (!formValues.password2) {
    errors.password2 = 'Required'
  } else if (formValues.password2 !== formValues.password) {
    errors.password2 = 'Passwords must match'
  }

  return errors;
}

const asyncValidate = async (values/*, dispatch */) => {
  try{
    // const response = await axios.get(`https://flickread.com/publish/api/account.php?username=${values.username}`)
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/user?username=${values.username}`)
    if( response.data.status == 'failure' ){
      throw { username: 'That username is already taken' }
    }
  }catch(e){
    throw { username: 'That username is already taken' }
  }
}

export default reduxForm({
  form: 'wizard', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate,
  asyncValidate,
  asyncBlurFields: ['username']
})(CompanyInfo);
