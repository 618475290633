import React from 'react';
import { reduxForm, getFormValues, formValueSelector, Field } from 'redux-form';
import { Button, Header, Message, Input, Grid, Modal } from 'semantic-ui-react';
import { connect } from 'react-redux';
import axios from 'axios';
import StripeCheckout from 'react-stripe-checkout';
import LogoWhite from '../../../assets/logo_flickread.png';
import './PaymentInfo.css'
import RenderField from '../common/RenderFieldRedux';

import { CheckoutModal } from './CheckoutContainer';
import { PRICE_TYPE, PLAN_TYPE, APP_OPTION } from './common'



class PaymentInfo extends React.Component {

  state = {
    clientToken: null,
    showBuyButton: false,
    buyButtonColor: 'grey',
    planType: '',
    errors: '',
    plan: '',
    stripePublishableKey: null,
    priceTotal: null,
    priceTotalAfterTaxes: null,
    
    productList: [],
    promoCode: null,
    promoCodePattern: '',
    promoCodeFound: null,
    showCheckoutModal: false
  }

  constructor(props) {
    super(props);
    this.promoCodeCheck = ''
  }

  generateLocalStorageData = (priceTotalAfterTaxes) => {

    const data = {      
      username: this.props.username,
      name: this.props.name,
      email: this.props.email,
      magazine: this.props.magazine,
      appOption: this.props.appOption,
      planType: this.props.planType,
      name: this.props.name,
      password: this.props.password,
      phone: this.props.phone,
      addressOne: this.props.addressOne,
      addressTwo: this.props.addressTwo,
      addressThree: this.props.addressThree,
      addressFour: this.props.addressFour,
      postZip: this.props.postZip,
      companyName: this.props.companyName,
      country: this.props.country,
      price: priceTotalAfterTaxes
    }
    // Storing data in localStorage
    localStorage.setItem("data", JSON.stringify(data) );
  }


  async componentDidMount() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let foo = params.get('type');

    if (foo === 'plus') {
      this.setState({ plan: ' Plus' })
    }

    // Get stripe publishable key
    let response = await axios.get(`${process.env.REACT_APP_API_URL}/api/settings`)
    this.setState({stripePublishableKey: response.data.publishableKey})

    // Get product list
    response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v2/product`)
    const productList = response.data
    this.setState({ productList })

    // Compute priceTotal
    const {priceTotal, priceTotalAfterTaxes} = this.getPrice(productList)


    // Stores data in localStorage
    this.generateLocalStorageData(priceTotalAfterTaxes)
  }

  confirmFree = (e) => {
    e.preventDefault();
    this.props.handleSubmit()
  }

  getPrice = (productList, promoCode = null) => {
    let { planType, appOption, country } = this.props;
    const product = productList.find( item => item.key == planType)
    const app = productList.find( item => item.key == appOption)
    // Fallback
    if( product == null ) return

    country = 'GB'
    // Proceed
    const percentage = promoCode?(100-promoCode.percentage) / 100.0:1
    const priceTotal = ( product.price+app.price )* percentage
    
    
    // Compute pricePlusVAT
    const priceTotalPlanType = country === "GB"?product.pricePlusVAT:product.price  
    const priceTotalAppOption = country === "GB"?app.pricePlusVAT:app.price  
    let priceTotalAfterTaxes =  priceTotalPlanType + priceTotalAppOption
    priceTotalAfterTaxes =  priceTotalAfterTaxes * percentage

    console.log( "priceTotal ", priceTotal )
    console.log( "priceTotalAfterTaxes ", priceTotalAfterTaxes )
    console.log( "percentage ", percentage )

    // Store priceTotal
    this.props.change('priceTotalAfterTaxes', priceTotalAfterTaxes);
    this.setState({ priceTotal, priceTotalAfterTaxes, promoCode })



    return { priceTotal, priceTotalAfterTaxes }
  };

  getHeaderContent = () => {
    const { planType, country } = this.props;
    const product = this.state.productList.find( item => item.key == planType)
    // Fallback
    if( product == null ) return
    // Proceed
    return `${product.name} @ £${this.state.priceTotal?.toFixed(2)}/month ${country === "GB" ? "+ VAT" : ""}`
  }

  onApplyPromoCode = async () => {
    try{
      this.promoCodeCheck = this.state.promoCodePattern
      const body = { name: this.state.promoCodePattern }
      let response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v2/promocode/check/`, body)
      const promoCode = response.data
      console.log("response.data ", response.data)
      this.setState({ promoCode, promoCodeFound: true })
      // Get new price
      const {priceTotal, priceTotalAfterTaxes} = this.getPrice(this.state.productList, promoCode)

      // Stores data in localStorage
      this.generateLocalStorageData(priceTotalAfterTaxes)
    }catch(e){
      this.setState({ promoCodeFound: false })
      console.log("failed", e)
      this.getPrice(this.state.productList)
    }
  }



  render() {
    const errors = this.state.errors;
    const { previousPage } = this.props;

    console.log("promoCodeFound ", this.state.promoCodeFound)

    return (
      <div>
        <CheckoutModal 
          open={this.state.showCheckoutModal} 
          onClose={() => this.setState({ showCheckoutModal: false}) }
          email={this.props.email}
          username={this.props.username}
          planType={this.props.planType}
          appOption={this.props.appOption}
          price={this.state.priceTotalAfterTaxes} 
          price_type={this.props.country=="GB"?PRICE_TYPE.VAT:PRICE_TYPE.TAXFREE} 
          promoCode={this.state.promoCode} />
        <Field
          component={RenderField}
          name="priceTotalAfterTaxes"
          value={this.state.priceTotalAfterTaxes}
          type="hidden"
        />


        <div>
          {this.state.promoCodeFound === true?
          <div style={{ marginBottom: "10px" }}>
            <Header size="small">          
              <label>PromoCode {this.promoCodeCheck} was applied</label>              
            </Header>
          </div>
          :null}
          {this.state.promoCodeFound === false?
          <div style={{ marginBottom: "10px" }}>
            <Header size="small">          
              <label>PromoCode {this.promoCodeCheck} was not found</label>              
            </Header>
          </div>
          :null}

          <Grid stackable>
            <Grid.Row centered>
              <Grid.Column width={4} >
                <Input fluid size='large' label={"Promo Code"} value={this.state.promoCodePattern}
                  labelPosition='left' type={"text"} style={{ padding: 0, height: '30px', fontSize: '12px' }} 
                  onChange={(e) => this.setState({ promoCodePattern: e.target.value })}/>
              </Grid.Column>
              <Grid.Column width={2} >
                <Button color='blue' onClick={() => this.onApplyPromoCode()} 
                  style={{ background: '#969696', height: '100%', width: '100%', fontSize: '12px' }}>Apply</Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>

        </div>
        <div style={{ marginTop: "20px" }}>
          <Header>You are about to subscribe to Flickread with<br />
            {this.getHeaderContent()}
          </Header>
        </div>
        {errors && <Message negative><Message.Header>{errors}</Message.Header></Message>}
        <div style={{ marginTop: "20px" }}>
          {/* <Button color='blue' onClick={previousPage} 
            style={{ background: '#ea5348'}}>Previous</Button> */}
          <Button color='blue' onClick={() => this.setState({ showCheckoutModal: true})} 
            style={{ background: '#ea5348', fontSize: '20px' }}>Proceed</Button>
        </div>
      </div>
    )
  }

}
//}

const validate = (formValues) => {
  const errors = {};
  return errors;
}

const formWrapped = reduxForm({
  form: 'wizard', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate
})(PaymentInfo)

// const selector = formValueSelector('wizard')
// export default connect(state => {
//   const { planType, name, magazine, companyName, price, country, email, appOption } 
//     = selector(state, 'planType', 'name', 'magazine', 'companyName', 'price', 'country', 'email', 'appOption')

//   const values = getFormValues('myForm')(state);
//   return { planType, name, magazine, companyName, price, country, email, appOption, values }
// })(formWrapped);


export default connect(state => {
  const values = getFormValues('wizard')(state);
  return { ...values }
})(formWrapped);
